import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Page from '../versions/caleb/template/page/page-layout'
import Carousel from '../versions/caleb/carousel'

const lang = 'it'
const Chi = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "io"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  const slides = []
  const addImage = (item) => {
    slides.push({
      img: item.childImageSharp.gatsbyImageData,
      label: '',
    })
  }
  data.allFile.nodes.map((item) => {
    addImage(item)
  })
  const options = {}
  // options.sideBg = '#a50000'

  const side = (
    <>
      <Carousel
        {...{ lang }}
        {...{ slides }}
      />
    </>
  )
  const main = <>bb</>
  const secondary = <>cc</>
  // const lang = 'it'
  return <Page {...{ side }} {...{ main }} {...{ secondary }} {...{ options }} />
}
export default Chi
